import React, { useState } from "react"

const DEFAULT_LABEL = "Choose file"

const FileInput = React.forwardRef(
  ({ className = "", inputOptions = {}, label = DEFAULT_LABEL }, ref) => {
    const [filename, setFilename] = useState("")
    const onChange = () => {
      const files = ref.current.files
      const fname =
        files.length > 0
          ? Array.prototype.map.call(files, f => f.name).join()
          : ""

      setFilename(fname)

      if (typeof inputOptions.onChange === "function") {
        inputOptions.onChange()
      }
    }

    return (
      <label
        className={`file-input ${className}`}
        data-filename={filename || "No file chosen"}
        data-label={label}
      >
        <input
          {...inputOptions}
          type="file"
          aria-label={label}
          ref={ref}
          onChange={onChange}
        />
      </label>
    )
  }
)

export default FileInput
