import React from "react"
import classNames from "clsx"
import JSZip from "jszip"
import { saveAs } from "file-saver"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import FileInput from "../components/FileInput"

const DEFAULT_CONVERSION_QUALITY = 75

const CONVERSION_STATUS_INITIAL = 0
const CONVERSION_STATUS_RUNNING = 1
const CONVERSION_STATUS_DONE = 2

class HomePage extends React.Component {
  constructor(props) {
    super(props)

    this.prepareImages = this.prepareImages.bind(this)
    this.convertImages = this.convertImages.bind(this)

    this.state = {
      userImages: [],
      conversionGlobalSettings: {
        quality: DEFAULT_CONVERSION_QUALITY,
      },
      conversionStatus: CONVERSION_STATUS_INITIAL,
    }

    this.imagesInputRef = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.conversionGlobalSettings.quality !==
      this.state.conversionGlobalSettings.quality
    ) {
      this.setImagesConversionQuality({
        images: this.getAllImages(),
        quality: this.state.conversionGlobalSettings.quality,
      })
    }
  }

  setConversionGlobalSettings(settings) {
    this.setState({
      conversionGlobalSettings: {
        ...this.state.conversionGlobalSettings,
        ...settings,
      },
    })
  }

  getAllImages() {
    return this.state.userImages.map((v, i) => i)
  }

  getImageBaseName(filename) {
    return filename.substring(0, filename.lastIndexOf("."))
  }

  getWebpImageFilename(originalFilename) {
    return `${this.getImageBaseName(originalFilename)}.webp`
  }

  prepareImages() {
    window.gtag("event", "load_images")

    const images = Array.from(this.imagesInputRef.current.files)

    this.setState({
      userImages: images.map(img => ({
        filename: img.name,
        url: URL.createObjectURL(img),
        conversionQuality: this.state.conversionGlobalSettings.quality,
        conversionStatus: CONVERSION_STATUS_INITIAL,
        convertedImageUrl: "",
      })),
      conversionStatus: CONVERSION_STATUS_INITIAL,
    })
  }

  setImagesConversionQuality({ images, quality }) {
    const userImages = this.state.userImages.map((img, i) => {
      if (images.includes(i)) {
        return { ...img, conversionQuality: quality }
      }

      return img
    })

    this.setState({ userImages })
  }

  removeImages({ images }) {
    const userImages = this.state.userImages.reduce((acc, img, i) => {
      if (images.includes(i)) {
        URL.revokeObjectURL(img.url)

        if (img.convertedImageUrl !== "") {
          URL.revokeObjectURL(img.convertedImageUrl)
        }

        return acc
      }

      acc.push(img)
      return acc
    }, [])

    this.setState({ userImages })
  }

  async convertImages({ images }) {
    window.gtag("event", "convert_images")

    this.setState({ conversionStatus: CONVERSION_STATUS_RUNNING })

    for (const i of images) {
      const img = this.state.userImages[i]
      const imgRaw = await fetch(img.url).then(res => res.arrayBuffer())
      const webpFilename = this.getWebpImageFilename(img.filename)

      window.FS.writeFile(img.filename, new Uint8Array(imgRaw))
      window.callMain([
        "-q",
        String(img.conversionQuality),
        img.filename,
        "-o",
        webpFilename,
      ])

      const convertedImage = window.FS.readFile(webpFilename)
      const convertedImageUrl = URL.createObjectURL(
        new Blob([convertedImage.buffer], { type: "image/webp" })
      )

      window.FS.unlink(img.filename)
      window.FS.unlink(webpFilename)

      this.setState({
        userImages: this.state.userImages.map(image => {
          if (image === img) {
            return {
              ...image,
              convertedImageUrl,
              conversionStatus: CONVERSION_STATUS_DONE,
            }
          }
          return image
        }),
      })
    }

    this.setState({ conversionStatus: CONVERSION_STATUS_DONE })
  }

  async downloadConvertedImages({ images }) {
    if (images.length === 1) {
      const img = this.state.userImages[images[0]]
      saveAs(img.convertedImageUrl, this.getWebpImageFilename(img.filename))
      return
    }

    const zip = new JSZip()

    for (const i of images) {
      const img = this.state.userImages[i]

      zip.file(
        this.getWebpImageFilename(img.filename),
        await fetch(img.convertedImageUrl).then(res => res.blob())
      )
    }

    saveAs(await zip.generateAsync({ type: "blob" }), "converted-images.zip")
  }

  renderImages() {
    let mainBtn = null

    if (this.state.conversionStatus === CONVERSION_STATUS_DONE) {
      mainBtn = (
        <button
          className="btn__primary"
          type="button"
          onClick={() =>
            this.downloadConvertedImages({
              images: this.getAllImages(),
            })
          }
        >
          DOWNLOAD ALL
        </button>
      )
    } else if (this.state.conversionStatus === CONVERSION_STATUS_RUNNING) {
      mainBtn = (
        <button className="btn__primary" type="button">
          CONVERTING...
        </button>
      )
    } else {
      mainBtn = (
        <button
          className="btn__primary"
          type="button"
          onClick={() => this.convertImages({ images: this.getAllImages() })}
        >
          CONVERT
        </button>
      )
    }

    return (
      <section className="conversion">
        <div className="inner">
          <div className="conversion-settings">
            <h2 className="text__m">GLOBAL SETTINGS</h2>

            <div className="item">
              <div className="text__s label">QUALITY</div>
              <div className="text__m value">
                {this.state.conversionGlobalSettings.quality}
              </div>
              <div className="control">
                <input
                  type="range"
                  min="0"
                  max="100"
                  step="1"
                  value={this.state.conversionGlobalSettings.quality}
                  disabled={
                    this.state.conversionStatus !== CONVERSION_STATUS_INITIAL
                  }
                  onChange={e =>
                    this.setConversionGlobalSettings({
                      quality: +e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="actions">
              {mainBtn}

              <button
                className="btn__secondary"
                type="button"
                disabled={
                  this.state.conversionStatus === CONVERSION_STATUS_RUNNING
                }
                onClick={() =>
                  this.removeImages({
                    images: this.getAllImages(),
                  })
                }
              >
                REMOVE ALL
              </button>
            </div>
          </div>

          <div className="conversion-images">
            {this.state.userImages.map((img, i) => (
              <div
                className={classNames("conversion-image", {
                  "conversion-running":
                    this.state.conversionStatus === CONVERSION_STATUS_RUNNING &&
                    img.conversionStatus !== CONVERSION_STATUS_DONE,
                  "conversion-done":
                    img.conversionStatus === CONVERSION_STATUS_DONE,
                })}
                key={i}
              >
                <div className="image-detail">
                  <div className="thumbnail">
                    <img src={img.url} alt={img.filename} />
                  </div>

                  <div className="item">
                    <div className="text__s label">NAME</div>
                    <div className="text__m value">{img.filename}</div>
                  </div>

                  <div className="item">
                    <div className="text__s label">QUALITY</div>
                    <div className="text__m value">{img.conversionQuality}</div>
                    <div className="control">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        step="1"
                        value={img.conversionQuality}
                        disabled={
                          this.state.conversionStatus !==
                          CONVERSION_STATUS_INITIAL
                        }
                        onChange={e =>
                          this.setImagesConversionQuality({
                            images: [i],
                            quality: +e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="actions">
                  {img.conversionStatus === CONVERSION_STATUS_DONE ? (
                    <a
                      className="btn__primary"
                      download={this.getWebpImageFilename(img.filename)}
                      href={img.convertedImageUrl}
                    >
                      DOWNLOAD
                    </a>
                  ) : null}

                  <button
                    className="btn__secondary"
                    type="button"
                    disabled={
                      this.state.conversionStatus === CONVERSION_STATUS_RUNNING
                    }
                    onClick={() => this.removeImages({ images: [i] })}
                  >
                    REMOVE
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }

  renderInput() {
    return (
      <section className="conversion-input">
        <div className="inner">
          <FileInput
            label="CHOOSE IMAGES"
            inputOptions={{
              accept: "image/png, image/jpeg",
              multiple: true,
              onChange: this.prepareImages,
            }}
            ref={this.imagesInputRef}
          />
        </div>
      </section>
    )
  }

  render() {
    return (
      <Layout mainClassName="home-page">
        <SEO pathname={this.props.location.pathname} />
        {this.state.userImages.length > 0
          ? this.renderImages()
          : this.renderInput()}
      </Layout>
    )
  }
}

export default HomePage
